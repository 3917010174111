import React from "react";
import HeaderNew from "../components/header-new";
import Footer from "../components/footer";
import "./faq.css";
import { useState } from "react";
import Accordion from "./accordion";
import { accordionData } from "./faqData";
import GoogleTranslate from "./GoogleTranslate";
import { MdLanguage } from "react-icons/all";

function Faq() {
  const { title, content, image } = accordionData;

  return (
    <div>
      <HeaderNew />
      <div className="faq-Main">
      <div className="faq-heading">
        <h2>Frequently Asked Questions</h2>
        <p> Farming on Seedify-defi is easy!</p>
      </div>

      <div className="accordion-item">
        <div className="accordion">
          {accordionData.map(({ title, content, image }) => (
            <Accordion title={title} content={content} image={image} />
          ))}
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
