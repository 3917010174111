import React, { Component } from "react";
import { connect } from "react-redux";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import CompleteIcon from "../../assets/complete.png";
import ErrorIcon from "../../assets/error.png";
import GreenCIcon from "../../assets/green-close.png";
import RedCIcon from "../../assets/red-close.png";
import BlueCIcon from "../../assets/close-vector.png";
import ProcessLoader from "../../assets/processing-loader.gif";
import { web3 } from "../../web3";
import getContractAddresses from "../../contractData/contractAddress/addresses";
import { getContractInstance } from "../../helpers/function";
import { actions } from "../../actions";
import { FaCalculator, FaChevronDown } from "react-icons/fa";
import HexagonLoader from "../../assets/loader.gif";
import Timer from "../timer";
import { GoAlert } from "react-icons/go";
import ReactTooltip from "react-tooltip";
import Collapse from "@kunukn/react-collapse";
import axios from "axios";
import { AiFillCaretDown } from "react-icons/all";
import { AiFillCaretUp } from "react-icons/all";
import { HiExternalLink } from "react-icons/all";
import tokenAbi from '../../contractData/abis/token.json';

const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 30 30" width={40} height={40}>
    <line
      x1="15"
      y1="15"
      x2="25"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
    <line
      x1="25"
      y1="15"
      x2="15"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
  </svg>
);

const closeIconRed = (
  <svg fill="currentColor" viewBox="0 0 30 30" width={40} height={40}>
    <line
      x1="15"
      y1="15"
      x2="25"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
    <line
      x1="25"
      y1="15"
      x2="15"
      y2="25"
      stroke="#000"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-miterlimit="16"
    ></line>
  </svg>
);

class Farming extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen1: false,
      isOpen2: false,
      web3Data: {
        isLoggedIn: false,
        accounts: [],
      },
      contractTimes: {},
      totalSupply: null,
      rate: null,
      lockDuration: null,
      rewardsAtMaturity: null,
      deposits: null,
      allowance: null,
      amount: { index: false, value: "" },
      activeContract: 7,
      userBalance: 0,
      txnStatus: { status: "false", msg: "" },
      error: { index: false, msg: "" },
      networkId: 97,
      poolDetails: null,
      userPoolDetails: null,
      poolContracts: [],
      openFirst: false,
      openFifth: false,
      txnCompleteModal: false,
      stakeFailedModal: false,
      currentFunction: "stake",
      txnHash: "",
      testamt: {},
      showMiniModals: true,
      timeLeft: -1,
      bnbUSDPrice: 0,
      currentSwap: "bakerySwap",
      mainLoader: false,
      APY: "-",
      poolsDropdown: false,
      FarmData: [],
      userFarmData: [],
      selectedPoolId: null,
      contractDataCalled: false,
      showMore: false,
    };
    this.toggleShowMore = this.toggleShowMore.bind(this);
  }


  static async getDerivedStateFromProps(nextProps, prevState) {
    let { web3Data } = nextProps;
    if (web3Data !== prevState.web3Data) return { web3Data: web3Data };
    else return null;
  }

  async componentDidUpdate(prevProps, prevState) {
    let { web3Data, currentSwap } = this.props;
    const { FarmData, contractDataCalled } = this.state;
    if (FarmData !== prevState.FarmData && !contractDataCalled) {
      this.setState({ contractDataCalled: true }, () =>

        this.callContractMethods(FarmData),
      );

      if (web3Data.isLoggedIn) {
        this.callUserData(web3Data, FarmData);
      }
    }

    if (currentSwap !== prevProps.currentSwap) {
      this.setState({ timeLeft: -1 });
      // if (web3Data.isLoggedIn) this.callUserData(web3Data);
      // this.callContractMethods();
    }
    const { poolDetails, userPoolDetails, timeLeft } = this.state;
    // if (web3Data.accounts[0] !== prevProps.web3Data.accounts[0]) {
    //   this.setState({ web3Data }, () => {
    //     if (web3Data.isLoggedIn) {
    //       this.loopUserData(web3Data);
    //       this.closePopUp();
    //     }
    //   });
    //   if (web3Data.isLoggedIn) this.checkLogOut();
    // }
    // if (this.state.web3Data.accounts[0] !== prevState.web3Data.accounts[0]) {
    //   this.loopUserData(web3Data);
    // }
    // if (poolDetails !== prevState.poolDetails) this.callSFUNDPrice();
    // if (userPoolDetails && poolDetails && timeLeft === -1) {
    //   this.setTimeLeft();
    // }
    // console.log(poolDetails)
  }


  setTimeLeft = (userSingleFarmData, key) => {
    if (this.state.timeLeft === -1) {
      const { FarmData } = this.state;
      console.log("farmData==========>", FarmData);
      console.log("FarmData[key].lockDuration=====>", FarmData[key]?.lock_duration);

      // Check if lockDuration is undefined
      if (FarmData[key]?.lock_duration !== undefined) {
        const currDuration = FarmData[key].lock_duration ? FarmData[key].lock_duration : 48;

        const _timeLeft = userSingleFarmData?.hasStaked
          ? userSingleFarmData?.userDeposits[1] && currDuration
            ? new Date().getTime() / 1000 +
            3 * (+userSingleFarmData?.userDeposits[1] + 1200 * currDuration - +userSingleFarmData?.currentBlock)
            : 0
          : 0;

        return _timeLeft;
      } else {
        // Handle the case where lockDuration is undefined
        // You can return a default value or handle it accordingly.
        return 0; // or any default value you want
      }
    }
  };




  checkLogOut() {
    const interValCheck = setInterval(() => {
      web3.eth.getAccounts().then((resp) => {
        if (!resp[0]) {
          this.props.getWeb3(1);
          clearInterval(interValCheck);
        }
      });
    }, 1000);
  }

  componentDidMount() {
    const { web3Data } = this.props;
    axios
      .get(
        "https://defiapi.seedify.blocsys.com/api/v1/stakeFarmRoute/getAllFarmPools"
        // "https://stakeapi.fibitpro.com/api/v1/stakeFarmRoute/getAllFarmPools"
      )
      .then((res) => {
        // console.log("Farm Data",res.data)
        const FarmData = res.data.data;
        this.setState({ FarmData });
        console.log("data", FarmData);
      });
    // this.callContractMethods();
    if (!web3Data.isLoggedIn) {
      if (!+localStorage.getItem("disconnected")) this.props.getWeb3();
    } else {
      this.setState({ web3Data: web3Data });
      // this.callUserData(web3Data);
    }

    // this.callSFUNDPrice();
    // this.getTokenSymbol();
    // await this.fetchToken0Info();

  }




  calculateAPY = async (pool, lpTokenAddress) => {
    return new Promise(async (resolve, reject) => {
      // const { poolDetails } = this.state;
      // console.log(pool)
      try {
        const pancakeLPContractInstance = getContractInstance(
          lpTokenAddress,
          "lpToken"
        );
        const reserves = await pancakeLPContractInstance.methods
          .getReserves()
          .call();
        const totalSupply = +web3.utils.fromWei(
          await pancakeLPContractInstance.methods.totalSupply().call()
        );
        const tokenPerLp = +web3.utils.fromWei(reserves[1]) / totalSupply;
        const APY = (this.amountCalc(1, true, pool) / (tokenPerLp * 2)) * 100;
        // ((poolDetails.totalReward - 475000) /
        //   (poolDetails.stakedBalance * tokenPerLp * 2)) *
        // 1200;
        resolve(APY);
      } catch (err) {
        reject(0);
      }
      // this.setState({ APY: +poolDetails.stakedBalance ? APY : "-" });
    });
  };
  loopUserData = (web3Data) => {
    setInterval(() => {
      // this.callContractMethods(true);
      // if (web3Data.isLoggedIn) this.callUserData(web3Data);
    }, 5000);
  };

  callUserData = async (web3Data, FarmData) => {
    const _userFarmData = await Promise.all(
      FarmData.map(async (pool, key) => {
        // const poolContracts = getContractAddresses();
        let obj = {};
        const farmingContractInstance = getContractInstance(
          pool.contractAddress,
          "farming"
        );
        const lpTokenContractInstance = getContractInstance(
          pool.lpTokenAddress,
          "fbtToken"
        );
        obj.totalSupply = await lpTokenContractInstance.methods
          .totalSupply()
          .call();
        console.log(1, obj.totalSupply);
        obj.currentBlock = await farmingContractInstance.methods
          .currentBlock()
          .call();
        // console.log(2, obj.currentBlock);
        obj.allowance = Number(
          web3.utils.fromWei(
            await lpTokenContractInstance.methods
              .allowance(web3Data.accounts[0], pool.contractAddress)
              .call()
          )
        );
        // console.log(2, obj.allowance);
        obj.userBalance = web3.utils.fromWei(
          await lpTokenContractInstance.methods
            .balanceOf(web3Data.accounts[0])
            .call(),
          "ether"
        );
        let rewardPerBlock = +web3.utils.fromWei(
          await farmingContractInstance.methods.rewPerBlock().call()
        );
        let stakedBalance = await farmingContractInstance.methods
          .stakedBalance()
          .call();
        let accShare = await farmingContractInstance.methods.accShare().call();
        let lockDuration = +(await farmingContractInstance.methods
          .lockDuration()
          .call());
        obj.userDeposits = await farmingContractInstance.methods
          .userDeposits(web3Data.accounts[0])
          .call();
        obj.hasStaked = await farmingContractInstance.methods
          .hasStaked(web3Data.accounts[0])
          .call();
        if (obj.hasStaked) {
          obj.oldRewards = +web3.utils.fromWei(
            await farmingContractInstance.methods
              .viewOldRewards(web3Data.accounts[0])
              .call()
          );
        }

        ///////////////////////////////////////////////////////////////
        // function addHexColor(c1, c2) {
        //   var hexStr = (parseInt(c1, 16) + parseInt(c2, 16)).toString(16);
        //   while (hexStr.length < 3) {
        //     hexStr = "0" + hexStr;
        //   } // Zero pad.
        //   return hexStr;
        // }
        // let key =
        //   "0x000000000000000000000000" + web3Data.accounts[0].substr(2, 42);
        // let newKey = web3.utils.soliditySha3(
        //   { type: "bytes32", value: key },
        //   { type: "uint", value: "15" }
        // );
        // let newHexkey =
        //   newKey.substr(0, newKey.length - 3) +
        //   addHexColor(newKey.substr(newKey.length - 3, newKey.length), 3);
        // //need to pass newKey + 3 into the next line.
        // let userAccShare = await web3.eth.getStorageAt(
        //   pool.contractAddress,
        //   newHexkey,
        //   obj.currentBlock
        // );
        //////////////////////////////////////////////////////////////////

        let period = await farmingContractInstance.methods.period().call();
        if (
          obj.hasStaked &&
          !+obj.oldRewards &&
          +period === +obj.userDeposits[3]
        ) {
          obj.poolShare =
            +(await farmingContractInstance.methods
              .fetchUserShare(web3Data.accounts[0])
              .call()) / 100;

          if (!obj.poolShare) {
            obj.poolShare = 0;
            obj.disableHarvest = true;
          } else {
            obj.poolShare = (+obj.userDeposits[0] / +stakedBalance) * 100;
            obj.poolShare =
              obj.poolShare < 0.000001
                ? "< 0.000001"
                : Number(obj.poolShare).toFixed(6);
          }

          obj.rewardsAtMaturity =
            +web3.utils.fromWei(
              await farmingContractInstance.methods
                .calculate(web3Data.accounts[0])
                .call()
            ) + obj.oldRewards;
          let lastRewardBlock = await farmingContractInstance.methods
            .lastRewardBlock()
            .call();
          obj.currentBlock = await farmingContractInstance.methods
            .currentBlock()
            .call();
          obj.endingBlock = await farmingContractInstance.methods
            .endingBlock()
            .call();
          const sVal =
            +obj.endingBlock > +obj.currentBlock
              ? obj.currentBlock
              : obj.endingBlock;
          // if (this.props.isV1 && obj.disableHarvest) {
          //   obj.rewardsAtMaturity = this.calculate_for_0_poolshare(
          //     obj.userDeposits[0],
          //     +sVal - +lastRewardBlock,
          //     rewardPerBlock,
          //     stakedBalance,
          //     accShare,
          //     userAccShare
          //   );
          // }
          obj.rewardsIfUnstakeToday = this.rewards_if_unstaked_today(
            obj.userDeposits,
            lockDuration,
            obj.rewardsAtMaturity
          );
        } else {
          obj.poolShare = "0";
          obj.rewardsAtMaturity = obj.oldRewards;
          obj.rewardsIfUnstakeToday = "0";
        }
        obj.timeLeft = this.setTimeLeft(obj, key);

        return obj;
      })
    );
    this.setState({ userFarmData: _userFarmData});
  };


  callContractMethods = async (FarmData) => {
    const _farmBlockData = await Promise.all(
      FarmData.map(async (pool) => {
        // if (!needloader) this.setState({ mainLoader: true });

        let obj = {};
        const farmingContractInstance = getContractInstance(
          pool.contractAddress,
          "farming"
        );
        // obj.contractAddress = web3.utils.toChecksumAddress(
        //   pool.contractAddress
        // );
        obj.period = await farmingContractInstance.methods.period().call();
        obj.currentBlock = await farmingContractInstance.methods
          .currentBlock()
          .call();
        obj.stakedBalance = +web3.utils.fromWei(
          await farmingContractInstance.methods.stakedBalance().call()
        );
        obj.lockDuration = +(await farmingContractInstance.methods
          .lockDuration()
          .call());
        obj.accShare = await farmingContractInstance.methods.accShare().call();
        obj.totalReward = +web3.utils.fromWei(
          await farmingContractInstance.methods.totalReward().call()
        );
        let endAccShare = await farmingContractInstance.methods
          .endAccShare(+obj.period ? +obj.period - 1 : 0)
          .call();
        // obj.rewardDistributed =
        //   +obj.totalReward +
        //   +web3.utils.fromWei(endAccShare[5]) -
        //   +web3.utils.fromWei(
        //     await farmingContractInstance.methods.rewardBalance().call()
        //   );
        obj.rewardBalance = web3.utils.fromWei(
          await farmingContractInstance.methods.rewardBalance().call()
        );
        obj.totalParticipants = await farmingContractInstance.methods
          .totalParticipants()
          .call();

        obj.currentBlock = await farmingContractInstance.methods
          .currentBlock()
          .call();
        obj.startingBlock = await farmingContractInstance.methods
          .startingBlock()
          .call();
        obj.endingBlock = await farmingContractInstance.methods
          .endingBlock()
          .call();
        obj.rewardPerBlock =
          +web3.utils.fromWei(
            await farmingContractInstance.methods.rewPerBlock().call()
          ) * 20;
        obj.rewardDistributed =
          ((obj.currentBlock - obj.startingBlock) * obj.rewardPerBlock) / 20;
        obj.rewardDistributed =
          obj.rewardDistributed >= obj.totalReward
            ? obj.totalReward
            : obj.rewardDistributed;
        obj.APY = await this.calculateAPY(obj, pool.lpTokenAddress);
        return { ...pool, ...obj };
      })
    );
    // console.log(_farmBlockData);
    this.setState({ FarmData: _farmBlockData });
  };

  rewards_if_unstaked_today(depositsArr, lockDuration, rewardsAtMaturity) {
    let daysTillNow = Math.floor(
      (new Date().getTime() / 1000 - depositsArr[1]) / 86400
    );
    let calculatedDays =
      daysTillNow / lockDuration >= 1 ? 1 : daysTillNow / lockDuration;
    let amount = Number(
      +calculatedDays * (rewardsAtMaturity - web3.utils.fromWei(depositsArr[0]))
    );
    amount += +web3.utils.fromWei(depositsArr[0]);
    return amount;
  }

  popup = (error, msg, timer) => {
    this.setState((prevState) => ({
      ...prevState,
      txnStatus: { status: error, msg: msg },
    }));
    if (timer) {
      setTimeout(
        () =>
          this.setState((prevState) => ({
            ...prevState,
            txnStatus: { status: false, msg: msg },
          })),
        15000
      );
    }
  };



  approve = async (key) => {
    const { FarmData, web3Data, userFarmData } = this.state;

    const lpTokenContractInstance = getContractInstance(
      FarmData[key].lpTokenAddress,
      "lpToken"
    );
    // console.log("this is props", key, lpTokenContractInstance);
    if (!web3Data.isLoggedIn)
      return this.popup("error", "Please connect to metamask");
    const { userPoolDetails } = this.state;
    console.log(FarmData[key].contractAddress, userFarmData)
    await lpTokenContractInstance.methods
      .approve(FarmData[key].contractAddress, userFarmData[key].totalSupply)
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        // onTransactionHash(hash);
        this.setState({ txnHash: hash });
        return this.popup("process");
      })
      .on("receipt", (receipt) => {
        // this.callUserData(this.state.web3Data);
        window.removeEventListener("receipt", this.approve);
        return this.popup(
          "success",
          "Your farming is approved . You can stake now !"
        );
      })
      .on("error", (error) => {
        window.removeEventListener("error", this.withdraw);
        // return this.popup("error", error.message);
        return this.onTransactionError(error);
      });
  };
  withdraw = async () => {
    const {
      amount,
      web3Data,
      poolDetails,
      userPoolDetails,
      selectedPoolId,
      FarmData,
      userFarmData,
    } = this.state;
    const stakingContractInstance = getContractInstance(
      FarmData[selectedPoolId].contractAddress,
      "farming"
    );

    // console.log(FarmData[selectedPoolId].contractAddress)
    if (!web3Data.isLoggedIn)
      return this.popup("error", "Please connect to metamask");

    if ((+userFarmData[selectedPoolId]?.rewardsAtMaturity)?.toFixed(4) > 0.5) {
      console.log(userFarmData[selectedPoolId])
      return this.popup("error", "Please harvest your  first");
    }
    if (!+amount.value)
      return this.popup("error", "Please enter a valid amount to withdraw ");
    if (
      +web3.utils.fromWei(userFarmData[selectedPoolId]?.userDeposits[0]) <
      +amount.value
    ) {
      return this.popup("error", "Exceeded withdrawal amount");
    }
    if (
      +userFarmData[selectedPoolId]?.userDeposits[1] +
        1200 * FarmData[selectedPoolId].lockDuration >
      +FarmData[selectedPoolId].currentBlock
    ) {
      return this.popup("error", "Withdraw has not started yet");
    }
    await stakingContractInstance.methods
      .withdraw(web3.utils.toWei(amount.value))
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({ txnHash: hash, showMiniModals: false });
        return this.popup("process");
      })
      .on("receipt", (receipt) => {
        this.setState({
          txnCompleteModal: this.state.openFirst,
          openFirst: false,
          amount: { index: false, value: "" },
        });
        this.onReciept(receipt);
      })
      .on("error", (error) => {
        this.setState({ stakeFailedModal: this.state.openFirst });
        return this.onTransactionError(error);
      });
  };

  makeTransaction = async (fxnName) => {
    const { amount, web3Data, selectedPoolId, FarmData, userFarmData } =
      this.state;
    const stakingContractInstance = getContractInstance(
      FarmData[selectedPoolId].contractAddress,
      "farming"
    );

    // console.log(
    //   "user pool details",
    //   userFarmData[selectedPoolId].allowance,
    //   amount.value
    // );
    if (amount.value > userFarmData[selectedPoolId].allowance) {
      return this.approve();
    }
    if (!web3Data.isLoggedIn)
      return this.popup("error", "Please connect to metamask");
    if (
      FarmData[selectedPoolId].startingBlock >
      FarmData[selectedPoolId].currentBlock
    )
      return this.popup("error", "Staking period has not started yet");
    if (
      FarmData[selectedPoolId].currentBlock >
      FarmData[selectedPoolId].endingBlock
    )
      return this.popup("error", "Staking period has ended");
    if (!+amount.value) return this.popup("error", "Please enter the amount");
    if (+amount.value > +userFarmData[selectedPoolId].userBalance)
      return this.popup("error", "Not enough balance");
    this.popup(false, "");
    await stakingContractInstance.methods[fxnName](
      web3.utils.toWei(amount.value)
    )
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({ txnHash: hash, showMiniModals: false });
        return this.popup("process");
      })
      .on("receipt", (receipt) => {
        this.setState({
          txnCompleteModal: this.state.openFirst,
          openFirst: false,
          amount: { index: false, value: "" },
        });
        this.onReciept(receipt);
      })
      .on("error", (error) => {
        this.setState({ stakeFailedModal: this.state.openFirst });
        return this.onTransactionError(error);
      });
  };
  async renew(key) {
    const { FarmData, web3Data } = this.state;
    const stakingContractInstance = getContractInstance(
      FarmData[key].contractAddress,
      "farming"
    );
    if (!web3Data.isLoggedIn)
      return this.popup("error", "Please connect to metamask");
    if (FarmData[key].currentBlock > FarmData[key].endingBlock) {
      return this.popup("error", "Period ended");
    }
    this.popup("false", "");
    await stakingContractInstance.methods
      .renew()
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({ txnHash: hash });
        return this.popup("process");
      })
      .on("receipt", (receipt) => {
        this.onReciept(receipt);
      })
      .on("error", (error) => {
        return this.onTransactionError(error);
      });
  }

  claimRewards = async (selectedPoolId) => {
    const { web3Data, FarmData, userFarmData } = this.state;
    const stakingContractInstance = getContractInstance(
      FarmData[selectedPoolId].contractAddress,
      "farming"
    );
    if (!web3Data.isLoggedIn)
      return this.popup("error", "Please connect to metamask");
    if (!+userFarmData[selectedPoolId]?.rewardsAtMaturity)
      return this.popup("error", "No rewards to harvest");
    this.popup("false", "");
    let fxn = +userFarmData[selectedPoolId]?.oldRewards
      ? "claimOldRewards"
      : "claimRewards";
    await stakingContractInstance.methods[fxn]()
      .send({ from: web3Data.accounts[0] })
      .on("transactionHash", (hash) => {
        this.setState({ txnHash: hash });
        return this.popup("process");
      })
      .on("receipt", (receipt) => {
        this.onReciept(receipt);
      })
      .on("error", (error) => {
        return this.onTransactionError(error);
      });
  };

  onReciept = (receipt) => {
    if (receipt.status) {
      // this.callUserData(this.state.web3Data);
      this.popup("success", "Transaction Successful", true);
    } else {
      console.log("error");
    }
  };

  onTransactionError = (error, modal) => {
    let msg = "Transaction reverted";
    if (error.code === 4001) {
      msg = "Transaction denied by user";
    } else if (error.code === -32602) {
      msg = "wrong parameters";
    } else if (error.code === -32603) {
      msg = "Internal Error";
    } else if (error.code === -32002) {
      msg = "Complete previous request";
    }
    this.popup("error", msg, true);
  };

  async toggle(index) {
    let collapse = "isOpen" + index;
    this.setState((prevState) => ({ [collapse]: !prevState[collapse] }));
  }
  closePopUp = () => {
    this.setState({ isOpen1: false });
  };
  closePopUp = () => {
    this.setState({ isOpen2: false });
  };
  refreshStates = () => {
    this.setState({
      error: { index: false, msg: "" },
      txnStatus: { status: false, msg: "" },
    });
  };
  setPoolType = (closedPool) => {
    this.setState({
      poolContracts: closedPool ? this.props.closedPools : this.props.openPools,
      txnStatus: { status: false, msg: "" },
    });
  };

  calculate_for_0_poolshare = (
    amt,
    noOfBlocks,
    rewardPerBlock,
    stakedBalance,
    accShare,
    userAccShare
  ) => {
    let rewards = noOfBlocks * web3.utils.toWei(rewardPerBlock.toString());
    let newStakebalance = +stakedBalance ? +stakedBalance : 100;
    let newAccShare = +accShare + (rewards * 1e6) / newStakebalance;
    let rewDebt = (+amt * userAccShare) / 1e6;
    let rew = ((+amt * newAccShare) / 1e6 - rewDebt) / 10 ** 18;

    return rew;
  };

  amountCalc = (amt, isApy, pool) => {
    let { rewardPerBlock, stakedBalance, accShare } = pool;
    let obj = { 1: 0, 7: 0, 30: 0 };
    //  console.log(poolDetails)
    if (pool) {
      function calc(noOfDays) {
        let rewards =
          noOfDays * 28800 * web3.utils.toWei((rewardPerBlock / 20).toString());
        let newStakebalance = +stakedBalance
          ? +web3.utils.toWei((stakedBalance + +amt).toString())
          : 100;
        let newAccShare = +accShare + (rewards * 1e6) / newStakebalance;
        let rewDebt = (+amt * 10 ** 18 * accShare) / 1e6;
        let rew = ((+amt * 10 ** 18 * newAccShare) / 1e6 - rewDebt) / 10 ** 18;
        return rew.toFixed(4);
      }

      // console.log("this", calc(365));
      const n = 1;
      if (isApy) return calc(365);
      obj["1"] = calc(1);
      obj["7"] = calc(7);
      obj["30"] = calc(30);

      this.setState((prevState) => ({
        testamt: { ...prevState.testamt, ...obj },
      }));
    }
  };



  // Api integration

  // componentDidMount() {
  //   axios
  //     .get(
  //       "http://indexapi.43.204.181.147.nip.io/api/v1/stakeFarmRoute/getAllFarmPools"
  //     )
  //     .then((res) => {
  //       // console.log("Farm Data",res.data)
  //       const FarmData = res.data.data;
  //       this.setState({ FarmData });
  //       console.log(FarmData);
  //     });
  // }
  // card dyanamic
  handleToggle = () => {
    this.setState({
      isActive: !this.state.isActive,
      activeCard: this.props._id,
    });
  };

  toggleShowMore = () => {
    // Toggle the state when "Read more" is clicked
    this.setState(prevState => ({
      showMore: !prevState.showMore,
    }));
  };
  render() {
    const {
      poolDetails,
      userPoolDetails,
      amount,
      txnStatus,
      openFirst,
      currentFunction,
      txnCompleteModal,
      stakeFailedModal,
      txnHash,
      testamt,
      showMiniModals,
      timeLeft,
      userFarmData,
      FarmData,
      selectedPoolId,
      buyToken,
      showMore
    } = this.state;
    // console.log('1');
    const showRenew = (key) =>
      FarmData[key]?.period !== userFarmData[key]?.userDeposits[3] &&
      +userFarmData[key]?.userDeposits[0] > 0;
    const isZeroLeft = (key) =>
      FarmData[key]?.rewardDistributed >= FarmData[key]?.totalReward;
    if (this.state.mainLoader)
      return (
        <div className="loader">
          <img className="loader-img" src={HexagonLoader} alt="" />
        </div>
      );
    console.log("timeleft============>", timeLeft)
    const isActive = this.state.isActive;
    // console.log(
    //   "distributed",
    //   FarmData[0]?.rewardDistributed?.toLocaleString(4)
    // );
    function call(val, val2, val3, val4) {
      console.log("this is av", val, val2, val3, val4);
    }

    return (
      <>
        <div className="FarmCardGrid">
          {this.state.FarmData.map((farmPool, key) => (
            console.log("fardm api data============>", FarmData),
            <div key={farmPool.id}>
              {
                // poolDetails &&
                <div className="stake-banner-bottom farm-banner-bottom">
                  {/* {userPoolDetails?.disableHarvest ? (
                    <ReactTooltip className="custom-tooltip" />
                  ) : null} */}
                  {/* <div className='c-dropdown'>
              <span
                onClick={() =>
                  this.setState({ poolsDropdown: !this.state.poolsDropdown })
                }
              >
                {this.props.swaps[this.props.currentSwapIndex].name}
                <FaChevronDown />
              </span>
              <Collapse
                onInit={() => this.setState({ poolsDropdown: false })}
                isOpen={this.state.poolsDropdown}
                className='cbox'
              >
                {this.props.swaps.map((swap, key) => (
                  <button
                    id={key}
                    onClick={() => {
                      this.props.setCurrentSwap(key);
                      this.setState({ poolsDropdown: false });
                    }}
                  >
                    {swap.name}
                  </button>
                ))}
              </Collapse>
            </div> */}

                  <div className="img-container">
                    <div className="imgdiv">
                      <img
                        // src="https://www.coolztricks.com/wp-content/uploads/2022/07/unnamed-1-300x300.png"
                        src={farmPool.logo}
                      // alt="img"
                      />
                    </div>
                    <div className="img-name">
                      <div className="container">
                        <h5>{farmPool.name}</h5>
                        <h6>{farmPool.tokenSymbol} - SFUND</h6>

                      </div>
                      <div className="DescDiv">
                        <p>
                          {showMore ? farmPool.Description : `${farmPool.Description.slice(0, 90)}...`}
                        </p>
                        {farmPool.Description.length > 90 && (
                          <div>
                            <span
                              style={{ cursor: 'pointer', color: '#fff', display: "inline", }}
                              onClick={this.toggleShowMore}
                            >
                              {showMore ? ' ...less' : '...more'}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                  <a
                    className="harvest-btn1"
                    href={farmPool.tokenUrl}
                    target="blank"
                  >
                    Buy {farmPool.tokenSymbol}
                  </a>
                  <div className="pool-title">

                    {/* <h3>{this.props.farmCardName}</h3> */}
                    <p className="pt-right">
                      {/* <div>
                  {+userPoolDetails?.rewardsAtMaturity
                    ? +userPoolDetails?.rewardsAtMaturity < 0.00001
                      ? "< 0.00001"
                      : (+userPoolDetails?.rewardsAtMaturity)?.toFixed(4)
                    : null}{" "}
                  FBT <span>earned</span>
                </div> */}

                      {/* <div className="H-outer">
                  {userPoolDetails?.disableHarvest ? (
                    <>
                      <button
                        className="harvest-btn"
                        onClick={() => this.claimRewards()}
                        disabled={userPoolDetails?.disableHarvest}
                      >
                        Harvest
                        {this.props.isV1 && (
                          <GoAlert data-tip="*There is a known bug regarding farmers with less than 0.01% pool share. Their rewards will be airdropped instead of being harvested." />
                        )}
                      </button>
                    </>
                  ) : (
                    <button
                      className="harvest-btn"
                      onClick={() => this.claimRewards()}
                      disabled={userPoolDetails?.disableHarvest}
                    >
                      Harvest
                      <GoAlert data-tip="*There is a known bug regarding farmers with less than 0.01% pool share. Their rewards will be airdropped instead of being harvested." />
                    </button>
                  )}
                 </div> */}
                    </p>
                  </div>
                  <div className="farm-row">
                    <p>
                      APR{" "}
                      <span>
                        {isZeroLeft(key) ? 0 : (+farmPool.APY).toFixed(2)} %
                      </span>
                    </p>
                  </div>
                  <div className="farm-row">
                    <p>
                      REWARD PER MINUTE{" "}
                      <span>
                        {isZeroLeft(key)
                          ? 0
                          : farmPool?.rewardPerBlock?.toFixed(5)}{" "}
                        {farmPool.tokenSymbol}
                      </span>
                    </p>
                  </div>
                  <div className="farm-row">
                    <p>
                      UNLOCKS IN{" "}
                      <span>
                        {!userFarmData[key] ? (
                          "7 days"
                        ) : +userFarmData[key]?.timeLeft === -1 ||
                          +userFarmData[key]?.timeLeft === 0 ? (
                          "7 Days"
                        ) : userFarmData[key]?.timeLeft >
                          new Date().getTime() / 1000 ? (
                          <Timer timeLeft={userFarmData[key]?.timeLeft} />
                        ) : (
                          "Already Reached"
                        )}

                      </span>
                    </p>
                  </div>
                  <div className="farm-row harvestBG">
                    <p>
                      <div className="sfund-earned">
                        {farmPool.tokenSymbol} earned{" "}
                        <span>
                          {+userFarmData[key]?.rewardsAtMaturity
                            ? +userFarmData[key]?.rewardsAtMaturity < 0.00001
                              ? "< 0.00001"
                              : (+userFarmData[key]?.rewardsAtMaturity)?.toFixed(4)
                            : null}
                        </span>
                      </div>
                      <div className="harvest-btn-container">
                        {userFarmData[key]?.disableHarvest ? (
                          <>
                            <button
                              className="harvest-btn"
                              onClick={() => this.claimRewards(key)}
                            >
                              Harvest
                              {this.props.isV1 && (
                                <GoAlert data-tip="*There is a known bug regarding farmers with less than 0.01% pool share. Their rewards will be airdropped instead of being harvested." />
                              )}
                            </button>
                          </>
                        ) : (
                          <button
                            className="harvest-btn"
                            onClick={() => this.claimRewards(key)}
                            disabled={userFarmData[key]?.disableHarvest}
                          >
                            Harvest
                            {/* <GoAlert data-tip="*There is a known bug regarding farmers with less than 0.01% pool share. Their rewards will be airdropped instead of being harvested." /> */}
                          </button>
                        )}
                      </div>
                    </p>
                  </div>


                  <div className="farm-row no-border">
                    <p>
                      DISTRIBUTED TOKENS{" "}
                      <span>
                        {/* {call(
                          key,
                          FarmData,
                          FarmData[key],
                          FarmData[key]?.rewardDistributed?.toLocaleString(4)
                        )} */}
                        {farmPool?.rewardDistributed?.toLocaleString(4)}
                        <b> / </b>
                        {farmPool?.totalReward?.toLocaleString(4)}
                      </span>
                    </p>
                  </div>

                  <hr className="hrline" />
                  {!Number(userFarmData[key]?.allowance) ? (
                    <button
                      className="approve-stake-btn"
                      onClick={() => this.approve(key)}

                      disabled={isZeroLeft(key)}
                    >
                      {isZeroLeft(key) ? "Closed" : "Approve"}
                    </button>
                  ) : +userFarmData[key]?.userDeposits[0] ? (
                    <div className="button-list">
                      <ReactTooltip className="custom-tooltip" />
                      {/* <button
                    className='approve-stake-btn alert-btn-2'
                    onClick={() => this.renew()}
                  >
                    Renew <GoAlert data-tip='text' />
                  </button> */}

                      {showRenew(key) ? (
                        <button
                          className="approve-stake-btn"
                          onClick={() => this.renew(key)}
                        >
                          Renew{" "}
                          <GoAlert data-tip="After renewal your accumulated rewards will get transferred to your wallet. Then you can unstake all LP tokens and stake those in new pools. " />
                        </button>
                      ) : (
                        <button
                          className="approve-stake-btn"
                          disabled={isZeroLeft(key)}
                          onClick={() =>
                            this.setState({
                              openFirst: true,
                              currentFunction: "stake",
                              selectedPoolId: key,
                            })
                          }
                        >
                          {isZeroLeft(key) ? "Closed" : "Stake"}
                        </button>
                      )}
                      <button
                        className="approve-stake-btn"
                        onClick={() =>
                          this.setState({
                            openFirst: true,
                            currentFunction: "withdraw",
                            selectedPoolId: key,
                          })
                        }
                      >
                        Unstake LP
                      </button>
                    </div>
                  ) : Number(userFarmData[0]?.allowance) ? (
                    <button
                      className="approve-stake-btn"
                      disabled={isZeroLeft(key)}
                      onClick={() =>
                        this.setState({
                          openFirst: true,
                          currentFunction: "stake",
                          selectedPoolId: key,
                        })
                      }
                    >
                      {isZeroLeft(key) ? "Closed" : "Stake"}
                    </button>
                  ) : null}

                  <div className="accordian-container">
                    <div
                      className="accordian-text"
                      onClick={() =>
                        this.setState({
                          selectedPoolId: selectedPoolId === key ? null : key,
                        })
                      }
                    >
                      <div>{key === selectedPoolId ? "Hide" : "More"}</div>
                      <div>
                        {key === selectedPoolId ? (
                          <AiFillCaretUp />
                        ) : (
                          <AiFillCaretDown />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      key === selectedPoolId ? null : "accordian-data-null"
                    }
                  >
                    <div className="accordian-data">
                      <div> YOUR STAKE </div>
                      <div className="accordian-wallet-data">
                        {userFarmData
                          ? (+web3.utils.fromWei(
                            userFarmData[key]
                              ? userFarmData[key].userDeposits[0]
                              : "0"
                          )).toFixed(4) +
                          " " +
                          ` ${this.props.token}`
                          : "-"}
                      </div>
                    </div>
                    <div className="accordian-data">
                      <div>PARTICIPANTS </div>
                      <div className="accordian-wallet-data">
                        {farmPool?.totalParticipants}
                      </div>
                    </div>
                    <div className="accordian-data">
                      <div>POOL SHARE </div>
                      <div className="accordian-wallet-data">
                        {userFarmData[key]
                          ? userFarmData[key]?.poolShare +
                          //   .toFixed(14)
                          //   .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1') +
                          // //  userPoolDetails?.poolShare?.toLocaleString(4)
                          " %"
                          : "-"}
                      </div>
                    </div>
                    <div className="accordian-data">
                      <div>
                        {" "}
                        <a
                          href={farmPool.poolUrl}
                          className="viewpoolbtn"
                          target="blank"
                        >
                          View Pool{" "}
                        </a>{" "}
                        <HiExternalLink />
                      </div>
                    </div>
                    <div className="accordian-data">
                      {/* <div>
                        <a
                          className="viewpoolbtn"
                          href="https://pancakeswap.finance/swap?outputCurrency=0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44"
                          target="blank"
                        >
                          Buy FBT <HiExternalLink />
                        </a>
                      </div> */}
                    </div>

                    <div className="accordian-data">
                      <div>Balance : </div>
                      <div className="accordian-wallet-data">
                        {" "}
                        {userFarmData[key]
                          ? (+userFarmData[key]?.userBalance).toLocaleString(
                            undefined,
                            4
                          )
                          : 0}{" "}
                        {this.props.token}
                      </div>
                    </div>
                  </div>
                  {/* <div className="farm-row">
              <p>
                YOUR STAKE{" "}
                <span>
                  {userPoolDetails
                    ? (+web3.utils.fromWei(
                        userPoolDetails ? userPoolDetails.userDeposits[0] : "0"
                      )).toFixed(4) +
                      " " +
                      ` ${this.props.token}`
                    : "-"}
                </span>
              </p>
            </div> */}
                  {/* <div className="farm-row">
              <p>
                POOL SHARE{" "}
                <span>
                  {userPoolDetails
                    ? userPoolDetails?.poolShare +
                      //   .toFixed(14)
                      //   .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1') +
                      // //  userPoolDetails?.poolShare?.toLocaleString(4)
                      " %"
                    : "-"}
                </span>
              </p>
            </div> */}
                  {/* <div className="farm-row">
              <p>
                PARTICIPANTS <span>{poolDetails?.totalParticipants}</span>
              </p>
            </div> */}
                  {/* <div className="farm-row no-border">
              <p>
                DISTRIBUTED TOKENS{" "}
                <span>
                  {poolDetails?.rewardDistributed?.toLocaleString(4)}
                  <b> / </b>
                  {poolDetails?.totalReward?.toLocaleString(4)}
                </span>
              </p>
            </div> */}
                  {/* progressbar */}
                  {/* <div className="custom-progressbar">
              {[
                ...Array(
                  poolDetails
                    ? Math.floor(
                        (+poolDetails?.rewardDistributed /
                          (+poolDetails?.totalReward
                            ? +poolDetails?.totalReward
                            : 1)) *
                          50
                      )
                    : 1
                ).keys(),
              ].map((key) => (
                <div className="bar active"></div>
              ))}
            </div> */}

                  {/* <div className="farm-row">
              <p onClick={() => window.open(`${this.props.viewPool}`)}>
                View Pool
              </p>
            </div> */}
                  {/* <button
              className="viewpoolbtn"
              onClick={() => window.open(`${this.props.viewPool}`)}
            >
              View Pool
            </button> */}
                  {/* {!Number(userPoolDetails?.allowance) ? (
              <button
                className="approve-stake-btn"
                onClick={() => this.approve()}
                disabled={isZeroLeft}
              >
                {isZeroLeft ? "Closed" : "Approve"}
              </button>
            ) : null} */}

                  {/* <div className="farm-row">
              <p>
                Balance :{" "}
                <span>
                  {" "}
                  {userPoolDetails
                    ? (+userPoolDetails?.userBalance).toLocaleString(
                        undefined,
                        4
                      )
                    : 0}{" "}
                  {this.props.token}
                </span>
              </p>
            </div> */}
                  {/* <label className="calc-div">
              Balance :{" "}
              {userPoolDetails
                ? (+userPoolDetails?.userBalance).toLocaleString(undefined, 4)
                : 0}{" "}
              {this.props.token}
              <FaCalculator
                onClick={() => this.setState({ openFifth: true })}
              />
            </label> */}

                  {/* {Number(userPoolDetails?.allowance) ? ( */}
                </div>
              }
            </div>
          ))}
        </div>
        <div>
          <Modal
            classNames={{ modal: "customModal2" }}
            open={openFirst}
            onClose={() =>
              this.setState({
                openFirst: false,
                showMiniModals: true,
              })
            }
            closeIcon={closeIcon}
            center
          >
            <div className="withdraw-box">
              {txnStatus.status === "process" ? (
                <div className="loader">
                  <img className="loader-img" src={HexagonLoader} alt="" />
                </div>
              ) : (
                <>
                  <ReactTooltip className="custom-tooltip" />
                  <h2>{currentFunction === "stake" ? "Farm" : "Withdraw"}</h2>
                  <label className="alert-outer">
                    Amount in {this.props.token}
                    {this.props.isV1 && (
                      <div
                        className="alert-btn"
                        data-tip="*There is a known bug regarding farmers with less than 0.01% pool share. Their rewards will be airdropped instead of being harvested."
                      >
                        <GoAlert />
                      </div>
                    )}
                  </label>
                  <div className="popup-form">
                    <div className="form-grp">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter amount"
                        value={amount.value}
                        onChange={(e) => {
                          if (!isNaN(e.target.value))
                            this.setState({
                              amount: { index: 0, value: e.target.value },
                            });
                        }}
                      />
                      <button
                        onClick={() =>
                          this.setState({
                            amount: {
                              index: 0,
                              value:
                                currentFunction === "stake"
                                  ? userFarmData[selectedPoolId]?.userBalance
                                  : web3.utils.fromWei(
                                    userFarmData[selectedPoolId]
                                      ? userFarmData[selectedPoolId]
                                        .userDeposits[0]
                                      : "0"
                                  ),
                            },
                          })
                        }
                      >
                        Max
                      </button>
                    </div>
                  </div>

                  <button
                    className="approve-stake-btn"
                    onClick={() =>
                      currentFunction === "stake"
                        ? this.makeTransaction("stake", 0)
                        : this.withdraw(0)
                    }
                  >
                    {amount?.value > userFarmData[selectedPoolId]?.allowance
                      ? "Approve"
                      : "Confirm"}
                  </button>
                </>
              )}
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            classNames={{ modal: "customModal5" }}
            open={stakeFailedModal}
            onClose={() =>
              this.setState({ stakeFailedModal: false, showMiniModals: true })
            }
            closeIcon={closeIconRed}
            center
          >
            <div className="wth-Frame-1">
              <img src={ErrorIcon} alt="" />
            </div>
            <div className="wth-Frame-2">
              <div className="withdraw-box">
                <h2>
                  {currentFunction === "stake" ? "Farm" : "Withdraw"} failed!
                </h2>
                <p>Something happened</p>
                <button className="approve-stake-btn">Try again</button>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            classNames={{ modal: "customModal4" }}
            open={txnCompleteModal}
            onClose={() =>
              this.setState({ txnCompleteModal: false, showMiniModals: true })
            }
            closeIcon={closeIcon}
            center
          >
            <div className="wth-Frame-1">
              <img src={CompleteIcon} alt="" />
            </div>
            <div className="wth-Frame-2">
              <div className="withdraw-box">
                <h2>
                  {currentFunction === "stake" ? "Farming" : "Withdraw"}{" "}
                  completed!
                </h2>
                {currentFunction === "stake" ? (
                  <p>Your LP tokens has been farmed</p>
                ) : null}
                <p>
                  Check your transaction{" "}
                  <a
                    href={`https://bscscan.com/tx/${txnHash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                </p>
                <button
                  className="approve-stake-btn"
                  onClick={() => this.setState({ txnCompleteModal: false })}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal>
        </div>
        {showMiniModals ? (
          <>
            {txnStatus.status === "success" ? (
              <div
                className="success-notification"
                onClick={() => this.popup(false, "")}
              >
                <div className="success-notification-inner">
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.popup(false, "")}
                  >
                    <img className="close-img" src={GreenCIcon} alt="" />
                  </a>
                  <h3>Success!</h3>
                  <p>{txnStatus.msg}</p>
                  <p>
                    Check your transaction{" "}
                    <a
                      href={`https://bscscan.com/tx/${txnHash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                  </p>
                </div>
              </div>
            ) : null}
            {txnStatus.status === "process" ? (
              <div className="process-notification">
                <div className="process-notification-inner">
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.popup(false, "")}
                  >
                    <img className="close-img" src={BlueCIcon} alt="" />
                  </a>
                  <h3>Processing...</h3>
                  <img className="loader-img" src={ProcessLoader} alt="" />
                </div>
              </div>
            ) : null}

            {txnStatus.status === "error" ? (
              <div className="error-notification">
                <div className="error-notification-inner">
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.popup(false, "")}
                  >
                    <img className="close-img" src={RedCIcon} alt="" />
                  </a>
                  <h3>Error!</h3>
                  <p>{txnStatus.msg}</p>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
        <div>
          <Modal
            classNames={{ modal: "customModal2" }}
            open={this.state.openFifth}
            onClose={() => this.setState({ openFifth: false })}
            closeIcon={closeIcon}
            center
          >
            <div className="withdraw-box">
              <h2>Farm Calculation</h2>
              <div class="farm-row">
                <div class="form-grp">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter amount"
                    // value=""
                    onChange={(e) => {
                      this.amountCalc(e.target.value);
                    }}
                  />
                </div>
                <p>
                  1d <span>{testamt[1]}</span>
                </p>
              </div>
              <div class="farm-row">
                <p>
                  7d <span>{testamt[7]}</span>
                </p>
              </div>
              <div class="farm-row">
                <p>
                  30d <span>{testamt[30]}</span>
                </p>
              </div>
              <div class="farm-row">
                <p>
                  APY <span>{this.state.APY}/100 %</span>
                </p>
              </div>
              <p className="text-left">
                Calculated based on current rates. Rates are estimates provided
                for your convenience only and by no means represent guaranteed
                returns.
              </p>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

const mapDipatchToProps = (dispatch) => {
  return {
    getWeb3: (val) => dispatch(actions.getWeb3(val)),
    enableMetamask: () => dispatch(actions.enableMetamask()),
    enabledWalletConnect: () => dispatch(actions.enabledWalletConnect()),
    // getOpenPools: () => dispatch(actions.getOpenPools()),
    // getKYCData: (address) => dispatch(actions.getKYCData(address)),
    // getClosedPools: () => dispatch(actions.getClosedPools()),
  };
};
const mapStateToProps = (state) => {
  return {
    web3Data: state.fetchWeb3Data,
    kycData: state.fetchKYCData,
    // openPools: state.fetchOpenPools,
    // closedPools: state.fetchClosedPools,
  };
};
export default connect(mapStateToProps, mapDipatchToProps)(Farming);
