import img from "../assets/metamask.png";
import viewpoolimg from '../assets/SeedifyPool.png'
import approvebtn from '../assets/SeedifyApprove.png'

export const accordionData = [
  {
    title: "How to Use Farms",
    content: `Yield Farming in Farms is a great way to earn SFUND rewards on Seedify-defi.
      Unlike Syrup Pools, Farms require you to stake two tokens to get LP Tokens, which you then stake in the Farm to earn rewards. This lets you earn SFUND while still keeping a position in your other tokens!`,
    // image: img,
  },
  {
    title: "Getting prepared",
    content: `Yield farming takes a little work to get set up.
      You’re going to need some "LP Tokens" to enter into a Farm with. Farms can only accept their own exact LP Token; for example, the SOIL test Farm will only accept SOIL test LP Tokens.
      To get the exact LP Token, you'll need to provide liquidity for that trading pair. So to get SOIL test LP Tokens, you'll first have to provide liquidity for the SOIL test pair.
      It may sound intimidating, but it's not too complicated. Let's go through step by step.`,
  },
  {
    title: "Finding your Farm",
    content: `Before you proceed, you'll want to choose a Farm that's right for you. Visit the Farms page and you’ll see a list of available Farms. 
    SOIL test are pinned to the top of the list. 
When you find a Farm that you'd like to use, note down the trading pair, e.g. SOIL test in case you need it later.

      `,
  },
  {
    title: "Providing liquidity to get LP Tokens",
    content: `Now that you've found a Farm to stake in, you will need to add liquidity to get your LP Tokens.
    Click on the row of the Farm you've chosen from the list. It will open to show more details.
    On the left, you'll see some links. Click on  the view pool LP Link .
    
    This will open the Add Liquidity page for your Farm's pair. We have a guide to adding liquidity you can follow to get your LP Tokens.

      `,
      image:viewpoolimg,
  },
  {
    title: "Putting your LP Tokens into the Farm",
    content: `Now that you have your LP Tokens, you’re ready to start staking them in a Farm and earning rewards! Go back to the Farms page and locate your Farm. Click anywhere on the row showing your pair. It will expand to show more details.When you’re ready, click the Approve button and confirm your action in your wallet.
      `,
      image:approvebtn
  },
  {
    title: "Collecting your farming rewards",
    content: `Farming will bring you SFUND rewards over time. You can collect (Harvest) these rewards and use them to get more LP Tokens, stake them in Pools, use them to play Lottery, or anything else you’d like.

      `,
  },
  {
    title: "Returning to your Farm to Harvest",
    content: `You can harvest your Farm  rewards together from the Farm page. If you'd like to collect your farming rewards only, follow along.
    To collect your rewards, you’ll need to visit your chosen Farm and collect the SFUND waiting for you.
    Return to the Farms page .
    Find the Farm you staked your LP Tokens in, and click the row to view details. You should see an estimate of your rewards under “SFUND earned”.
    
    Click the Harvest button and confirm the action in your wallet. After a short wait, the SFUND will be claimed to your wallet for you to use as you like.

      `,
  },
  {
    title: "How often should I harvest my rewards?",
    content: `How often you harvest your rewards is up to you, but it does help to remember that there is a small fee involved in harvesting.
    You can see this fee in your wallet when confirming after clicking Harvest.
    This shows the fee for harvesting as it appears in the MetaMask wallet. Different wallets will show the information a little differently. Consider leaving your rewards to grow for a while so you pay fees less often.
    And that's all there is to it! You may also want to look at how to use Seedify-defi Pools to earn rewards.
    Happy farming!
      `,
  },
];
