import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import WalletConnectQRCodeModal from "@walletconnect/qrcode-modal";
import detectEthereumProvider from "@metamask/detect-provider";

// var metamaskProvider;
// var walletConnectProvider;
const providers = {
  main: "https://bsc-dataseed.binance.org/",
  test: "https://data-seed-prebsc-2-s1.binance.org:8545", //https://data-seed-prebsc-1-s1.binance.org:8545/
};
let curr = "test";
let web3 = null;
const walletConnectProvider = new WalletConnectProvider({
  rpc: {
    // 0x38: 'https://speedy-nodes-nyc.moralis.io/e280a1853045f7d20dcb71e1/bsc/mainnet', // BSC Mainnet chainId - 56
    0x61: "https://data-seed-prebsc-1-s1.binance.org:8545/", // BSC Testnet chainId - 97
  },
  // chainId: 0x38, // BSC Mainnet
  chainId: 0x61, // BSC Testnet
  qrcode: false,
});

const metamaskConnectInit = () => {
  // Check if Web3 has been injected by the browser (Mist/MetaMask).
  return new Promise(async (resolve, reject) => {
    if (typeof window.web3 !== "undefined") {
      // Use Mist/MetaMask's provider.
      if (window.web3.__isMetaMaskShim__) {
        web3 = new Web3(window.web3.currentProvider);
        const provider = await detectEthereumProvider();
        web3 = new Web3(provider);
        resolve(true);
      } else {
        // if(window.web3. )
        // Use Mist/MetaMask's provider.
        const provider = await detectEthereumProvider();
        web3 = new Web3(provider);
        resolve(true);
      }
    } else {
      // Handle the case where the user doesn't have web3. Probably
      // show them a message telling them to install Metamask in
      // order to use the app.
      web3 = new Web3(new Web3.providers.HttpProvider(providers[curr]));
      reject(false);
    }
  });
};

const walletConnectInit = () => {
  // Check if WalleConnect has been conected by the website
  return new Promise((resolve, reject) => {
    if (!walletConnectProvider.connector.connected) {
      metamaskConnectInit();
      reject(false);
    } else {
      // Use WalletConnect provider.
      walletConnectProvider.enable();
      web3 = new Web3(walletConnectProvider);
      resolve(true);
    }
  });
};

const walletConnectModalInit = () => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("iswalletConnect", 1);
    walletConnectProvider.enable();
    web3 = new Web3(walletConnectProvider);
    // Wallet Connect Provider Events
    walletConnectProvider.connector.on("display_uri", (err, payload) => {
      const uri = payload.params[0];
      WalletConnectQRCodeModal.open(uri);
    });
    walletConnectProvider.on("connect", () => {
      WalletConnectQRCodeModal.close(); // close the QR scanner modal
    });
    walletConnectProvider.on("disconnect", (code, reason) => {
      localStorage.clear();
    });
    resolve(true);
  });
};

// const walletConnectInit = async () => {
//   localStorage.setItem("iswalletConnect", 1);
//   walletConnectProvider.enable();
//   web3 = new Web3(walletConnectProvider);
//   if (!walletConnectProvider.connector.connected) {
//     web3 = new Web3(
//       new Web3.providers.HttpProvider(
//         "https://mainnet.infura.io/v3/de21e440aade484290be1e3c89e67f28"
//         // "https://rinkeby.infura.io/v3/de21e440aade484290be1e3c89e67f28"
//       )
//     );
//   }
// };

// const walletConnectModalInit = async () => {
//   return new Promise(async (resolve, reject) => {
//     walletConnectInit();
//     const uri = walletConnectProvider.connector.uri;
//     // console.log(uri);
//     if (!walletConnectProvider.connector.connected) {
//       WalletConnectQRCodeModal.open(uri);
//     } else resolve(true);
//   });
// };

if (!web3) {
  if (Number(localStorage.getItem("iswalletConnect")) > 0) {
    walletConnectInit();
  } else metamaskConnectInit();
}

export {
  web3,
  walletConnectProvider,
  walletConnectInit,
  metamaskConnectInit,
  walletConnectModalInit,
};
